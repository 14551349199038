import React from 'react'
import Navbar from '../components/elements/Navbar'
import { Headline, Section, Subtitle } from './datenschutz'
import components from '../content/common'
import Footer from '../components/elements/Footer'
import { Link } from 'gatsby'

const IndexPage = () => {
  return (
    <>
      <Navbar content={components.navbar} />
      <Section>
        <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
          <Headline>Teilnahmebedingungen</Headline>
          <Subtitle>Teilnahmebedingungen</Subtitle>
          <p>
            <b>I. Geltungsbereich</b> <br /> Die Teilnahmebedingungen gelten
            ausschließlich für das Gewinnspiel{' '}
            <b>„kostenloses Probierpackerl“</b> auf{' '}
            <Link to="/">www.erdinger-brauhaus.de </Link>Veranstalter dieses
            Gewinnspiels ist: Privatbrauerei ERDINGER Weißbräu - Werner Brombach
            GmbH - Lange Zeile 1 und 3 - 85435 Erding (genannt im Folgenden:
            „ERDINGER Weißbräu“). Jeder Teilnehmer erkennt mit der Teilnahme an
            diesem Gewinnspiel die hier aufgeführten Teilnahmebedingungen an.
            <br />
            Wir verlosen 700x 1 Probierpaket unter allen bis zum 31.05.2022 um
            23:59 Uhr eingesendeten Teilnahmen.
          </p>
          <p>
            <b>II. Teilnahme</b> <br /> 1. Teilnahmeberechtigt sind
            ausschließlich Personen ab dem vollendeten 16. Lebensjahr mit
            Wohnsitz in den EU-Ländern. Mitarbeiter von ERDINGER Weißbräu und
            von mit ERDINGER Weißbräu kooperierenden Agenturen sind von der
            Gewinnspielteilnahme ausgeschlossen. Mehrfache Teilnahme,
            Gewinnspielclubs, automatisierte Einträge über Gewinnspiel-Robots
            sowie willentliche Falscheinträge und Einträge mit sog.
            „Wegwerf-E-Mail-Adressen“ sind unzulässig. <br /> 2.
            Gewinnspielteilnehmer ist derjenige, der das entsprechende
            Teilnahmeformular für ein „kostenloses Probierpackerl“ auf der
            Internetseite <Link to="/">www.erdinger-brauhaus.de </Link>
            mit seinen Kontaktdaten vollständig ausgefüllt hat. <br />
            3. Teilnahmeschluss für dieses Gewinnspiel ist der 31.05.2022.
          </p>
          <p>
            <b>III. Durchführung und Gewinn</b> <br />
            1. Die Gewinner werden mittels Losverfahren festgestellt. <br /> 2.
            Eine Barauszahlung des Gewinns ist nicht möglich <br /> 3. Für jeden
            Teilnehmer (natürliche Person) ist nur ein Gewinn möglich. <br /> 4.
            Jeder Gewinner erhält spätestens 14 Tage nach Teilnahmeschluss sein
            Probierpaket zugestellt. Für die Richtigkeit der angegebenen
            Telefonnummer sowie E-Mail/Post-Adresse ist der Teilnehmer
            verantwortlich. <br /> 5. Folgender Preis ist festgesetzt: 1
            Probierpaket ERDINGER Brauhaus Helles &amp; Naturradler <br /> 6.
            Die Lieferung erfolgt im Zeitraum vom 14.04.2022 bis 15.06.2022. Der
            Gewinner wird rechtzeitig über die genaue Anlieferung informiert und
            hat dafür Sorge zu tragen, dass die Lieferung in Empfang genommen
            werden kann. Die Lieferbedingungen zur Auslieferung der Gewinne
            werden von ERDINGER Weißbräu sowie deren Partnern festgelegt. Ist
            eine Übergabe des Gewinns nicht oder unter nicht zumutbaren
            Umständen möglich, haben die Gewinner keinen Anspruch auf eine
            Ersatzleistung. <br /> 7. Eine Übertragung des Gewinns an Dritte ist
            ohne die Zustimmung des Veranstalters nicht zulässig.
          </p>
          <p>
            <b>IV. Vorzeitige Beendigung, Ausschluss</b> <br /> 1. ERDINGER
            Weißbräu behält sich vor, das Gewinnspiel jederzeit ohne
            Vorankündigung und ohne Angabe von Gründen abzubrechen, auszusetzen
            oder zu beenden. Dies gilt insbesondere, falls eine ordnungsgemäße
            Durchführung aus technischen, rechtlichen oder sonstigen Gründen
            nicht gewährleistet werden kann. Den Teilnehmern stehen in einem
            solchen Fall keinerlei Ansprüche gegen ERDINGER Weißbräu zu. <br />{' '}
            2. ERDINGER Weißbräu behält sich vor, Teilnehmer von der Teilnahme
            am Gewinnspiel auszuschließen. Dies gilt insbesondere bei Verstößen
            gegen die Teilnahmebedingungen, mehrfacher Teilnahme oder falls sich
            Teilnehmer der Manipulation oder anderer unredlicher Hilfsmittel
            bedienen. ERDINGER Weißbräu kann einen solchen Ausschluss auch
            nachträglich aussprechen, Gewinne wieder aberkennen und diese
            zurückfordern.
          </p>
          <p>
            <b>V. Haftung </b>
            <br /> ERDINGER Weißbräu haftet nicht für Schäden, die durch Fehler,
            Verzögerungen oder Unterbrechungen in der Übermittlung, bei
            Störungen der technischen Anlagen und des Service, unrichtige
            Inhalte, Verlust oder Löschung von Daten, Viren oder in sonstiger
            Weise bei der Bewerbung oder Durchführung entstehen können, es sei
            denn, dass solche Schäden durch ERDINGER oder deren
            Erfüllungsgehilfen vorsätzlich, grob fahrlässig oder durch
            Verletzung von Kardinalspflichten verursacht werden.
          </p>
          <p>
            <b>VI. Sonstiges</b> <br /> 1. Der Rechtsweg ist ausgeschlossen. Die
            Durchführung des Gewinnspiels, das Rechtsverhältnis zwischen
            Veranstalter und Teilnehmer, sowie diese Teilnahmeregeln
            einschließlich ihrer Auslegung unterstehen ausschließlich dem
            deutschen Recht unter Ausschluss aller Rechtsnormen, die in eine
            andere Rechtsordnung verweisen.
            <br /> 2. Ansprechpartner und Verantwortlicher ist allein ERDINGER
            Weißbräu als Veranstalter.
            <br /> 3. Die Privatbrauerei Erdinger Weißbräu Werner Brombach GmbH
            beteiligt sich bei Streitigkeiten aus Verbraucherverträgen nicht an
            einem Schlichtungsverfahren gemäß Verbraucherstreitbelegungsgesetz.
            Die für Verbraucher zuständige Schlichtungsstelle ist die Allgemeine
            Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V. ,
            Straßburger Straße 8, 77694 Kehl. Verbraucher aus anderen EU-Staaten
            werden für Streitigkeiten aus einem Verbrauchervertrag auf die
            Online-Streitbeilegungsplattform der Europäischen Kommission
            hingewiesen:{' '}
            <a
              href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=DE"
              target="_blank"
            >
              https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=DE
            </a>
            <br />
            4. Sollten einzelne dieser Bestimmungen ungültig sein oder werden,
            bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon
            unberührt. <br /> 5. Diese Teilnahmebedingungen können jederzeit von
            ERDINGER Weißbräu ohne gesonderte Benachrichtigung geändert werden.
          </p>
          <p>
            <b>Datenschutzhinweis</b> <br /> Für die Durchführung des
            Gewinnspiels ist es erforderlich, dass ERDINGER Weißbräu und der
            beauftragte Dienstleister persönliche Daten (Name), bei Gewinnern
            auch die Adressen, speichern, um die Gewinner auszulosen und ihnen
            die Preise zukommen zu lassen. Die Speicherung von Daten erfolgt
            ausschließlich zum Zweck der Gewinnermittlung. Die Verarbeitung
            erfolgt nur durch ERDINGER Weißbräu und den beauftragten
            Dienstleistern und wird nur zwischen diesen Parteien weitergeben.
            Die Rechtsgrundlage für die Verarbeitung der Daten sind Art. 6 (1)
            lit. a&#41; und b&#41;. ERDINGER Weißbräu und beauftragte
            Dienstleister geben Daten nicht an Dritte weiter.{' '}
            <b>Alle Daten werden nach Ablauf des Gewinnspiels gelöscht.</b> Der
            Teilnehmer kann jederzeit seine Einwilligung in die Verarbeitung der
            Daten widerrufen, auch während des Gewinnspiel-Zeitraums, per E-Mail
            an{' '}
            <a href="mailto:gewinnspiel@erdinger.de">gewinnspiel@erdinger.de</a>{' '}
            . ERDINGER Weißbräu und die beauftragten Dienstleister löschen die
            Daten dann unverzüglich. Bei einer Löschung von Daten vor Ende des
            Gewinnspiels ist eine Teilnahme der entsprechenden Person nicht mehr
            möglich. Der Teilnehmer hat das Recht auf jederzeitige Auskunft über
            die personenbezogenen Daten, das Recht auf Berichtigung, das Recht
            auf Löschung, das Recht auf Einschränkung und das Recht auf
            Datenübertragbarkeit. Auf das Beschwerderecht an die zuständige
            Aufsichtsbehörde wird hingewiesen (
            <a href="mailto:poststelle@ldabayern.de">poststelle@ldabayern.de</a>{' '}
            – Bayerisches Landesamt für Datenschutzaufsicht, 91522 Ansbach).
            Verantwortlicher im Sinne der DSGVO ist die Privatbrauerei Erdinger
            Weißbräu Werner Brombach GmbH. Der Datenschutzbeauftragten ist
            erreichbar unter:{' '}
            <a href="mailto:datenschutzbeauftragter@erdinger.de">
              datenschutzbeauftragter@erdinger.de
            </a>
            . Weitere Hinweise findet man unter:{' '}
            <a
              href="https://de.erdinger.de/brauerei/datenschutz.html"
              target="_blank"
            >
              https://de.erdinger.de/brauerei/datenschutz.html
            </a>
            . <br /> Jeder Teilnehmer erklärt sich mit der Teilnahme mit diesem
            Vorgehen einverstanden. ERDINGER Weißbräu ist unter der
            E-Mail-Adresse:{' '}
            <a href="mailto:gewinnspiel@erdinger.de">gewinnspiel@erdinger.de</a>{' '}
            zu erreichen.
          </p>
        </div>
      </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default IndexPage
